<template>
  <div class="text-center hero">
    <img 
      class="mb-3 app-logo" 
      src="/logo.svg" 
      alt="" 
      width="200" 
    >
    <p class="lead">
      Промо бла, бла, бла.
    </p>
  </div>
</template>

<script>
export default {
  name: "Hero"
};
</script>
