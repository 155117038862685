<template>
  <div class="next-steps mb-4">
    <h2 class="my-5 text-center">Выберите ваш билет</h2>

    <div
      v-for="(ticket, t) in tickets"
      :key="`a${t}`"
      class="ticket"
    >
      <h3
        v-if="t % 2 == 0"
      >
        Билет {{ t / 2 + 1 }}
      </h3>
      <div class="mb-2">
        <div 
          v-for="(row, r) in ticket"
          :key="`c${t}${r}`"
          class="row m-0"
        >
          <div 
            v-for="(item, i) in row"
            :key="`c${t}${r}${i}`"
            class="col-1 py-4 text-center border"
          >
            <strong>{{ item ? item : '' }}</strong>
          </div>
        </div>
      </div>
      <button
        v-if="t % 2 != 0"
        class="btn btn-warning mb-4"
        @click="select(Math.floor(t / 2))"
      >
        Выбрать билет
      </button>

    </div>
    <hr>
    <button
      class="btn btn-warning mt-4"
      @click="generate()"
    >
      Еще
    </button>

    <b-modal 
      ref="error-modal" 
      hide-footer
    >
      <template #modal-title>
        Ошибка !
      </template>
      <div class="d-block text-center">
        <h3>{{ errorText }}</h3>
      </div>
    </b-modal>    

  </div>
</template>

<script>
  import { app } from '@/services'
  export default {
    name: "GeneratorTickets",
    data() {
      return {
        tickets: [],
        numbers: [],
        errorText: null
      }
    },
    created() {
      this.generate();
    },
    methods: {
      generate() {
        let tickets = [];
        let holes = [];

        let countNumbersInCols = [9,10,10,10,10,10,10,10,11];
        let numbersInCols = [];
        let number = 1;
        for (let col = 0; col <= countNumbersInCols.length; col++) {
          let numbersInRows = [];
          for (let row = 0; row < countNumbersInCols[col]; row++) {
            numbersInRows[row] = number;
            number++;
          }
          numbersInCols[col] = numbersInRows;
        }

        let ticketsNumbers = 6;
        let ticketHoles = [0, 0, 0, 0, 0, 0];
        for (let col = 0; col < countNumbersInCols.length; col++) {
          let holesNums = 0;
          let colHoles = [
            [], [], [], [], [], []
          ];
          let countHoles = 18 - countNumbersInCols[col];
          while (holesNums < countHoles) {
            for (let ticket = 0; ticket < ticketsNumbers; ticket++) {
              if (colHoles[ticket].length < 2 && holesNums < countHoles) {
                let hole = Math.floor(Math.random() * 3);
                if (colHoles[ticket].indexOf(hole) === -1) {
                  colHoles[ticket].push(hole);
                  holesNums++;
                  ticketHoles[ticket]++;
                }
              }
            }
          }
          holes.push(colHoles);
        }
        console.log('Tickets holes: ' + ticketHoles);
        let loop = 1;
        while (loop) {
          loop = 0;
          for (let col = 0; col < countNumbersInCols.length; col++) {
            let moveFromTicket = null;
            let holesCol = holes[col];
            for (let ticket = 0; ticket < ticketsNumbers; ticket++) {

              if (ticketHoles[ticket] > 12 && moveFromTicket == null) {
                loop = 1;
                if (holesCol[ticket].length == 2) {
                  moveFromTicket = ticket;
                  console.log('moveFromTicket: ' + moveFromTicket, col);
                }
              }

              if (ticketHoles[ticket] < 12 && moveFromTicket != null) {
                loop = 1;
                if (holesCol[ticket].length == 1) {
                  let hole = holesCol[ticket][0];
                  console.log('moveToTicket: ' + ticket, holesCol[moveFromTicket], holesCol[moveFromTicket][0], hole);

                  if (holesCol[moveFromTicket][0] != hole) {
                    holesCol[ticket][1] = holesCol[moveFromTicket][0];
                    holesCol[moveFromTicket].splice(0, 1);
                    ticketHoles[moveFromTicket]--;
                    moveFromTicket = null;
                    ticketHoles[ticket]++;
                  } else if (holesCol[moveFromTicket][1] != hole) {
                    holesCol[ticket][1] = holesCol[moveFromTicket][1];
                    holesCol[moveFromTicket].splice(1, 1);
                    ticketHoles[moveFromTicket]--;
                    moveFromTicket = null;
                    ticketHoles[ticket]++;
                  }
                }
              }
            }
          }
        }

        console.log('Tickets holes: ' + ticketHoles);

        for (let ticket = 0; ticket < ticketsNumbers; ticket++) {
          let countHoles = [0, 0, 0];
          for (let col = 0; col < countNumbersInCols.length; col++) {
            let idxs = holes[col][ticket];
            idxs.forEach(idx => countHoles[idx]++);
          }

          let loop = 0;
          console.log('Ticket: ' + ticket);
          console.log(countHoles);
          while (loop < 5) {
            //loop = 0;
            let moveFromRow = null;
            let moveToRow = null;
            for (let row = 0; row < 3; row++) {
              if (countHoles[row] != 4) {
                if (countHoles[row] > 4 && moveFromRow == null) {
                  moveFromRow = row;
                  //console.log('Set from row: ' + moveFromRow);
                }
                if (countHoles[row] < 4 && moveToRow == null) {
                  moveToRow = row;
                  //console.log('Set to row: ' + moveToRow);
                }
              }
              if (moveFromRow != null && moveToRow != null) {
                //console.log(moveFromRow + ' -> ' + moveToRow);
                let colsHoles = [];
                for (let col = 0; col < countNumbersInCols.length; col++) {
                  if (holes[col][ticket].indexOf(moveFromRow) != -1 && holes[col][ticket].indexOf(moveToRow) == -1) {
                    colsHoles.push(col);
                  }
                }
                console.log(moveFromRow + ' -> ' + moveToRow + ' Find hole in Cols: ' + colsHoles);
                let colRnd = colsHoles[Math.floor(Math.random() * colsHoles.length)];
                console.log('Selected Col: ' + colRnd);
                let colHoles = holes[colRnd][ticket];
                //holes[colRnd][ticket] = [];
                let idxHole = colHoles.indexOf(moveFromRow);
                console.log(colHoles);
                colHoles[idxHole] = moveToRow;
                console.log(colHoles);
                //holes[colRnd][ticket] = colHoles;
                countHoles[moveFromRow]--;
                countHoles[moveToRow]++;
                moveFromRow == null;
                moveToRow == null;
                console.log('Loop: ' + loop);
                break;
                //console.log(countHoles);
              }
            }
            loop++;
          }
          console.log(countHoles);
        }

        console.log(holes);

        for (let ticket = 0; ticket < ticketsNumbers; ticket++) {
          let ticketNumbers = [];
          let numbers = 0;
          for (let row = 0; row < 3; row++) {
            let ticketRow = [];
            for (let col = 0; col < countNumbersInCols.length; col++) {
              //console.log('Ticket: ' + ticket + ' Row: ' + row + ' Col: ' + col);
              //console.log(row * 9 + col);
              //console.log(numbersInCols[col]);
              //console.log(holes[col][ticket]);
              //let position = Math.floor(Math.random() * numbersInCols[col].length);
              //console.log(position);
              //numbersInCols[col].splice(position, 1);
              let colHoles = holes[col];
              //console.log(colHoles[ticket]);
              if (colHoles[ticket].indexOf(row) != -1) {
                ticketRow[col] = 0;
              } else {
                //ticketRow[col] = 1;
                numbers++;
                let position = Math.floor(Math.random() * numbersInCols[col].length);
                let number = numbersInCols[col][position];
                //console.log(number);
                numbersInCols[col].splice(position, 1);
                ticketRow[col] = number;

              }
            }
            ticketNumbers.push(ticketRow);
          }
          console.log('ticket: ' + ticket + ' Numbers: ' + numbers);
          tickets.push(ticketNumbers);
          //console.log(tickets);
        }
        console.log(tickets);
        this.tickets = tickets;
      },
      select(ticket) {
        console.log(ticket, this.tickets[ticket * 2], this.tickets[ticket * 2 + 1]);
          app.createTicket({
            ticket_user_id: 1, 
            ticket: [this.tickets[ticket * 2], this.tickets[ticket * 2 + 1]]
          }).then(res => {
            console.log(res);
            if (res.error_text) {
              this.errorText = res.error_text;
              this.$refs['error-modal'].show();
            }
          }).catch(err => console.error(err));
        }
    }
  };
</script>
