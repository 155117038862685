<template>
  <div class="container">
    <div class="row">
      T
    </div>

    <div class="row">
      <div
        v-for="(draw, d) in draws"
        :key="`draw${d}`"
        class="draw"
      >
        <h2>Тираж №{{ draw.lotto_draw_id }}. Дата розыгрыша {{ draw.lotto_draw_start }} - {{ draw.lotto_draw_end }}</h2>
        <div class="mb-2">
          Количество участвующих билетов: {{ draw.tickets_total }}
        </div>
        <div
          v-if="draw.lotto_draw_finished == 1"
        >
          <span class="text-danger">Тираж завершен</span>
          <div class="alert alert-secondary mb-4">
            Порядок выпадения чисел: {{ draw.lotto_draw_data.join(', ') }}
          </div>
          <div>
            <strong>Выигрышные блиеты:</strong><br>
            Тур 1: <br>
            Тур 2: №№ билетов {{ draw.winners_finished_card.join(', ') }} на {{ draw.winners_finished_card_step }} ходу.<br>
            Тур 3: №№ билетов {{ draw.winners_finished_ticket.join(', ') }} на {{ draw.winners_finished_ticket_step }} ходу.
          </div>
        </div>
        <div 
          v-else
          class="mb-4"
        >
          <button
            class="btn btn-primary"
          >
            Принять участие
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { app } from '@/services'
  export default {
    name: "Draws",
    data() {
      return {
        draws: [],
        numbers: [],
        errorText: null
      }
    },
    created() {
      console.log('BLA BLA');
      app.getDraws({
        //user_id: 1
      }).then(res => {
        console.log(res);
        if (res.error_text) {
          this.errorText = res.error_text;
          this.$refs['error-modal'].show();
        } else {
          this.draws = res.draws || [];
        }
      }).catch(err => console.error(err));
    },
  }
</script>
