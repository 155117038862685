<template>
  <div
    class="ticket"
  >
    <h3>
      Билет {{ ticket.ticket_id }}. Тираж: {{ ticket.lotto_draw_id }}. Дата: {{ ticket.last_update }}
    </h3>
    <div 
      v-for="(card, icard) in ticket.ticket_data"
      :key="`card-${ticket.ticket_id}-${icard}`"
      class="mb-2"
    >
      <div 
        v-for="(row, irow) in card"
        :key="`row-${ticket.ticket_id}-${icard}-${irow}`"
        class="row m-0"
      >
        <div 
          v-for="(item, i) in row"
          :key="`${ticket.ticket_id}-${icard}-${irow}-${i}`"
          :class="(ticket.lotto_draw.lotto_draw_data_not.indexOf(item) == -1) ? (numbers.indexOf(item) != -1) ? 'border-success table-success' : '' : 'border-danger table-danger'"
          class="col-1 py-4 text-center border"
        >
          <strong>{{ item != 0 ? item : '' }}</strong>
        </div>
      </div>
    </div>
    <div class="mb-1">
      Шаг: {{ step }}
    </div>
    <div class="mb-4">
      Порядок выпадения чисел:<br>
      <button
        v-for="(number, inumber) in ticket.lotto_draw.lotto_draw_data"
        :key="`number-${inumber}`"
        :class="(inumber != step) ? (inumber < step) ? 'btn-secondary disabled' : 'btn-outline-secondary disabled' : 'btn-outline-secondary'"
        class="btn py-0 px-2 me-1 mb-1"
        @click.prevent="clickNumber(inumber + 1, number)"
      >
        {{ number }}
      </button>
    </div>
    <div
      v-if="ticket.ticket_finished_step"
      class="alert alert-success"
    >
      <h3>Ваш билет выиграл на {{ ticket.ticket_finished_step }} ходу!</h3>
    </div>
  </div>
</template>

<script>
  //import { app } from '@/services'
  export default {
    name: "Ticket",
    props: {
      ticket: {
          type: Object,
          default() { return null; }
      },
    },
    data() {
      return {
        step: 0,
        numbers: [],
      }
    },
    created() {
    },
    methods: {
      clickNumber(step, number) {
        console.log(step, number);
        this.numbers.push(number);
        this.step++;
      }
    }
  };
</script>
