<template>
  <div class="container">
    <div class="row">
      T
    </div>

    <div class="row">
      <Ticket
        v-for="(ticket) in tickets"
        :key="`ticket-${ticket.ticket_id}`"
        :ticket="ticket"
      />
    </div>
  </div>
</template>

<script>
  import { app } from '@/services'
  import Ticket from "../components/Ticket";
  export default {
    name: "MyTickets",
    components: {
      Ticket
    },
    data() {
      return {
        tickets: [],
        errorText: null
      }
    },
    created() {
      console.log('BLA BLA');
      app.getUserTickets({
        user_id: 1
      }).then(res => {
        console.log(res);
        if (res.error_text) {
          this.errorText = res.error_text;
          this.$refs['error-modal'].show();
        } else {
          this.tickets = res.tickets || [];
        }
      }).catch(err => console.error(err));
    },
  }
</script>
