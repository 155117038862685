<template>
  <div>
    <hero />
    <hr>
    <home-content />
    <generator-tickets />
  </div>
</template>

<script>
import Hero from "../components/Hero";
import HomeContent from "../components/HomeContent";
import GeneratorTickets from "../components/GeneratorTickets";

export default {
  name: "Home",
  components: {
    Hero,
    HomeContent,
    GeneratorTickets
  }
};
</script>

<style lang="scss" scoped>
.next-steps {
  .fa-link {
    margin-right: 5px;
  }
}
</style>
