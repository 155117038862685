<template>
  <div class="next-steps">
    Описание
  </div>
</template>

<script>
export default {
  name: "HomeContent"
};
</script>
