import { app as settings } from '@/settings';
import { REST, RESTError } from './rest';

export default class extends REST {
    static get settings() {
        return settings;
    }

    static getDraws(params) {
        console.log(params);
        return this._post(`get_draws/`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить розыгрыши');
        });
    }
    static createTicket(params) {
        params.handler = 'CreateTicket';
        params.ticket = JSON.stringify(params.ticket);
        return this._post(`handler/`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось создать билет');
        });
    }
    static getUserTickets(params) {
        console.log(params);
        return this._post(`get_user_tickets/`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить билеты');
        });
    }
}
