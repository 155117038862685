<template>
  <div 
    id="app" 
    class="d-flex flex-column h-100"
  >
    <nav-bar />
    <div class="container flex-grow-1">
      <error />
      <div class="mt-5">
        <router-view />
      </div>
    </div>
    <footer class="bg-light text-center p-3">
      <div class="logo" />
      <p>
        Проект <a href="https://www.lradio.ru">L Radio</a>
      </p>
    </footer>
  </div>
</template>
<style>
  .logo {
    background-image: url('./assets/logo.svg');
  }
</style>
<script>
import NavBar from "./components/NavBar";
import Error from "./components/Error";

export default {
  components: {
    NavBar,
    Error
  }
};
</script>
